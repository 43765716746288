body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.avatar {
  vertical-align: middle;
  width: 80px;
  border-radius: 50%;
  border-width: 5px;
  border-color: gray;
  border-style: outset;
  margin: auto;
}

.avatar2 {
  width: 65px;
  border-radius: 50%;
  border-width: 5px;
  border-color: gray;
  border-style: outset;
  display: block;
  margin-left: 0;
  margin-right: auto;
}

/*.image-upload>input {
  display: none;
}*/

.user-button {
  padding: 0;
  border: 0;
}

.css-2gx1h6 {
  text-align: center;
}