.header {
    width: 100%;
    height: 80px;
    display: block;
    background: #1357a6;
}

.innerheader {
    width: 1300px;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.logosegment {
    height: 100%;
    display: table;
    float: left;
}

.logosegment h1 {
    color: white;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    font-size: 32px;
}

.navigation {
    float: right;
    height: 100%;
    margin: 0%;
}

.navigation a {
    height: 100%;
    display: table;
    float: left;
}

.navigation a:last-child {
    padding-right: 0;
}

.navigation a li:hover{
    font-weight: 600;
}
   
.navigation a:hover{
    background-color: #1663be;
}

.navigation a li {
    display: flex;
    align-items: center;
    height: 100%;
    
}

.navigation a li button {
    height: 80px;
    width: 100px;
    color: white;
    font-size: 16px;
    padding: 0;
    border: none;
    background: none;
}

.login {
    display: inline;
    align-items: center;
    float: right;
    height: 40px;
    width: 100px;
    color: white;
    font-size: 16px;
    padding: 0;
    margin-top: 20px;
    margin-left: 20px;
    background-color: #1663be;
    border-radius: 10px;
    border: 2px solid #fff;
}

.login:hover {
    background-color: #1870d5
}

.login:active {
  background-color: #1870d5;
  box-shadow: 0 5px rgb(18, 74, 121);
  transform: translateY(3px);
}