.testtest {
    background-color: rgb(59, 213, 59);
    background-color: rgb(252, 170, 70);
    background-color: rgb(255, 55, 55);
    background-color: rgb(81, 171, 220);

}

.vacc {
    background-color: rgb(159, 201, 159);
    background-color: rgb(59, 186, 59);
    background-color: rgb(65, 154, 65);
    background-color: rgb(59, 125, 59);
    background-color: rgb(48, 100, 48);

    background-color: rgb(128, 128, 128);
}

.barprofile {
    display: inline;
    align-items: center;
    padding: 10px 30px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    cursor: pointer;
    transition: transform .25s cubic-bezier(.7, .98, .86, .98), box-shadow .25s cubic-bezier(.7, .98, .86, .98);
    background-color: #fff;
    margin-bottom: 10px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0px;
}

.barprofile:hover {
    transform: scale(1.1);
    box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
}

.barname {
    color: #979cb0;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.64px;
    margin-left: 12px;
}

.barvalue {
    color: rgb(252, 170, 70);
    font-weight: 700;
    font-size: 24px;
    text-align: right;
    padding: 0px 20px;
}

.barvalue2 {
    color: rgb(255, 55, 55);
    font-weight: 700;
    font-size: 24px;
    text-align: right;
    padding: 0px 20px;
}

.barvalue3 {
    color: rgb(59, 213, 59);
    font-weight: 700;
    font-size: 24px;
    text-align: right;
    padding: 0px 20px;
}

.barAndData {
    display: flex;
    align-items: center;
    justify-content: center;
}

.barStats {
    margin: 10px;
}

.barBackground {
    padding-bottom: 30px;
}