.tableBackground {
    background-color: rgb(249, 249, 249);
    padding-top: 50px;
    padding-bottom: 100px;
    margin-bottom: 20px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

.table {
    color: #212529;
    margin-left: auto;
    margin-right: auto;
    font-style: Segoe UI;
    width: 100%;
    height: 500px;
    overflow-y: scroll;
}

.table td,
.table th {
    padding: .75rem;
    vertical-align: top;
    border: 2px solid #bbbbbb;
}

.table thead tr {
    background-color: #bbbbbb;
}

tr:nth-child(2n) {
    background-color: rgb(239, 239, 239);
}

tr:nth-child(2n + 1) {
    background-color: #e6e6e6
}

.filterOptions {
    margin: 10px;
}

.queryLabel {
    color: #7a7a7a;
    font-size: 20px;
    font-style: bold;
}

/*
.selectOption {
    padding: 10px;
    background-color: #fafafa;
    border: 2px solid #dee2e6;
    font-size: 14px;
    width: auto;
    border-radius: 5px;
    cursor: pointer;
}

.selectOption:focus,
.selectOption:hover {
    outline: none;
    border: 2px solid #dee2e6;
}

.selectOption option {
    background-color: #fafafa;
}
*/

.selectOption {
    width: 140px;
    height: 35px;
    padding: 5px 35px 5px 5px;
    font-size: 20px;
    border: none;
    width: auto;
    text-align: center;
    background: rgb(239, 239, 239);
    cursor: pointer;
    color: #7a7a7a;
}

.selectOption:focus {
    outline: none;
}

.selectOption option:checked {
    background-color: #d6d6d6;
    color: #3e3e3e;
}

.selectUnderline {
    width: auto;
    height: 3px;
    background-color: #989898;
    border-radius: 3px;
}

.allSelections {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.selectContainer {
    margin: 5px 10px;
}

.tableContainer {
    margin-top: 20px;
    height: 500px;
    overflow: scroll;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.table {
    position: sticky;
    top: 0;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track {
    display: none;
}

::-webkit-scrollbar-corner {
    display: none;
}