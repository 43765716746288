.vaccprofile {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    padding: 10px 30px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    cursor: pointer;
    transition: transform .25s cubic-bezier(.7, .98, .86, .98), box-shadow .25s cubic-bezier(.7, .98, .86, .98);
    background-color: #fff;
    margin-bottom: 10px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.vaccprofile:hover {
    transform: scale(1.1);
    box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
}

.vaccname {
    color: #979cb0;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.64px;
    margin-left: 12px;
}

.vaccvalue {
    color: rgb(59, 125, 59);
    font-weight: 700;
    font-size: 34px;
    text-align: right;
}

.vaccvalue2 {
    color: rgb(65, 154, 65);
    font-weight: 700;
    font-size: 34px;
    text-align: right;
}

.chartAndData {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vaccStats {
    margin: 10px;
}